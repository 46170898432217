import React from 'react';
import { Link } from 'react-router-dom';
import Image1 from "../../../../assets/at-cybersport-asses/Keyboard/Mehanika/MK853-11.png"

const ATK22 = () => {
  const productData = {
    title: 'Механическая клавиатура',
    description: 'Чистый звук и глубокие басы USB-C зарядка. Заряд нашуников 40mhA Заряд чехла наушников 500mhA Сенсорное управление Радиус действия 10м Время работы 5 часов BT V5.0+EDR Эргономичный дизайн',
    price: 300,
    image: Image1, // Replace with your actual image URL
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-950">
      <div className="max-w-7xl msx-h-full bg-zinc-900 rounded-2xl overflow-hidden shadow-xl lg:flex p-8 md:block">
        <div className="w-1/2">
          <img src={productData.image} alt={productData.title} className="w-full h-full object-cover" />
        </div>
        <div className="w-1/2 pl-12">
          <h2 className="text-3xl font-semibold text-gray-100 mb-4">{productData.title}</h2>
          <p className="text-lg text-gray-300 mb-6">{productData.description}</p>
          <p className="text-2xl font-semibold text-gray-100">&#8381; {productData.price.toFixed(0)}</p>
          <Link to="https://shop.post.tm/categories/at-cybersport/products">
          <button className="px-8 py-2 mx-36 my-6 bg-purple-800 font-bold hover:bg-white text-white hover:text-black hover:shadow-md transition duration-500 ease-in-out shadow-md hover:scale-110 justify-center rounded-full">
            Перейти в Маркетплейс
          </button>
          </Link>
          
        </div>
      </div>
    </div>
  );
};

export default ATK22;