import React from 'react';
import { Link , useParams } from 'react-router-dom';
import Image1 from "../../assets/at-cybersport-asses/Case/AT-PC1.png"
import { products } from '../../routings/ProductMap';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class SingleProductComponent extends React.Component  { 
  state = { product: []}  
  componentDidMount(){
    let { id } = this.props.params;
    this.setState({
      product: products[id] 

      });
    console.log(products[id])
  }
  
  render (){ 
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-950">
        <div className="max-w-8xl msx-h-full bg-zinc-900 rounded-3xl overflow-hidden md:mt-48 my-20 shadow-xl lg:flex md:p-0 lg:p-8 md:block">
          <div className="lg:w-1/2 w-full">
            <img src={this.state.product.image} className="w-full h-full object-cover" style={{maxHeight: '500px'}}/>
          </div>
          <div className="w-1/2 pl-4">
            <h2 className="text-3xl font-semibold text-gray-100 mb-4">{this.state.product.title}</h2>
            {this.state.product.descriptionText?.map((des,id)=>(
              <p className="text-lg text-gray-300 mb-6 " key={id}>{des.text}</p>
            ))}
            <p className="text-2xl font-semibold text-gray-100">  {this.state.product.price} </p>
            <Link to="https://market.yandex.ru/search?text=at%20cybersport&rs=eJwzclCy5OJNLFFIrkxKLSouyC8qETj26CGzEgsHgwAvmGSAkBoMWQxVHKbGpkbm5qZmXYxMHIwBjBsYGZxkuSS5ODgYBRglGBUYBZik2FNS0xJLc0oUgFoEGL04TMxMTS3SjCyCjAzNjQzMzQwMLQ2NzE30LSzNTQ1Skk3NUlMNDYGi5sbJKWmGScYGpoYpBmYGBvqG-oYAKRYglg%2C%2C&rt=11&glfilter=7893318%3A53527756">
            <button className="px-12 py-2 mx-36 my-6 bg-purple-800 font-bold hover:bg-white text-white hover:text-black hover:shadow-md transition duration-500 ease-in-out shadow-md hover:scale-110 justify-center rounded-full">
              Перейти в Маркетплейс
            </button>
            </Link>
            
          </div>
        </div>
      </div>
    );
  }
};

export default withParams(SingleProductComponent);

/*

function SingleProductComponent (props) {
  const productData = {
    title: 'Беспроводные наушники AT5',
    description: 
    'Чистый звук и глубокие басы USB-C зарядка. Заряд нашуников 40mhA Заряд чехла наушников 500mhA Сенсорное управление Радиус действия 10м Время работы 5 часов BT V5.0+EDR Эргономичный дизайн',
    price: 400,
    image: Image1, // Replace with your actual image URL
  };
  
  console.log(props.id)
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-950">
      <div className="max-w-7xl msx-h-full bg-zinc-900 rounded-2xl overflow-hidden shadow-xl lg:flex p-8 md:block">
        <div className="w-1/2">
          <img src={productData.image} alt={productData.title} className="w-full h-full object-cover" />
        </div>
        <div className="w-1/2 pl-12">
          <h2 className="text-3xl font-semibold text-gray-100 mb-4">{productData.title}</h2>
          <p className="text-lg text-gray-300 mb-6">{productData.description}</p>
          <p className="text-2xl font-semibold text-gray-100">&#8381; {productData.price.toFixed(0)}</p>
          <Link to="https://shop.post.tm/categories/at-cybersport/products">
          <button className="px-8 py-2 mx-36 my-6 bg-purple-800 font-bold hover:bg-white text-white hover:text-black hover:shadow-md transition duration-500 ease-in-out shadow-md hover:scale-110 justify-center rounded-full">
            Перейти в Маркетплейс
          </button>
          </Link>
          
        </div>
      </div>
    </div>
  );
};


*/