import React from "react";
import { Link, Route,Routes, BrowserRouter } from "react-router-dom";


                        // Import's of Components
import PreLoader from "../components/PreLoader/PreLoader";
import ProductPage from "../pages/ProductPage/ProductPage";
import SingleProductComponent from "../components/SingleProductComponent/SingleProductComponent";
import Navbar from "../components/Navbar/Navbar";
import BannerCarousel from "../components/BannerCarousel/BannerCarousel"
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AboutUs/AboutUs";
import VideoModal from "../components/VideoModal/VideoModal";
import SoftwarePage from "../pages/SoftwarePage/SoftwarePage";
import ContactUs from "../pages/ContactUs/ContactUs";
import { Breadcrumbs } from "@material-tailwind/react";

import KeyboardsProduct from "../components/Keyboards/KeyboardsProduct";
import CasesProduct from "../components/Cases/CasesProduct";
import HeadPhonesProduct from "../components/HeadPhones/HeadPhonesProduct";
import BluetoothHeadPhonesProduct from "../components/HeadPhones/BluetoothHeadPhonesProduct";
import MousesProduct from "../components/Mouses/MousesProduct";
import RugsProduct from "../components/Rugs/RugsProduct";
import WatchesProduct from "../components/Watches/WatchesProduct";
import ChairsProduct from "../components/Chairs/ChairsProduct";
import CamerasProduct from "../components/Cameras/CamerasProduct";
import ColumnsProduct from "../components/Columns/ColumnsProduct";

import ATK22 from "../components/SingleProductComponent/Keyboards/ATK22/ATK22";
import AT104 from "../components/SingleProductComponent/Keyboards/AT104/AT104";
import ATX5 from "../components/SingleProductComponent/Bluetooth/ATX5";
import GalleryComponent from "../components/GalleryComponent/GalleryComponent";




function Routings() {
    return(

        <BrowserRouter> 
                         <Breadcrumbs/>
            <Routes>


                <Route index path="/" element={<Home />} />
                <Route path="productpage" element={<ProductPage />} />
                <Route path="singleproduct/:id" element={<SingleProductComponent />} />
                <Route path="aboutus" element={<AboutUs />} />
                <Route path="videomodal" element={<VideoModal />} />
                <Route path="software" element={<SoftwarePage />} />
                <Route path="contact" element={<ContactUs />} />
                <Route path="gallery" element={<GalleryComponent />} />
                
                <Route path="keyboards" element={<KeyboardsProduct />} />
                <Route path="cases" element={<CasesProduct />} />
                <Route path="headphones" element={<HeadPhonesProduct />} />
                <Route path="bluetoothheadphones" element={<BluetoothHeadPhonesProduct />} />
                <Route path="mouses" element={<MousesProduct />} />
                <Route path="rugs" element={<RugsProduct />} />
                <Route path="watches" element={<WatchesProduct />} />
                <Route path="cameras" element={<CamerasProduct />} />
                <Route path="columns" element={<ColumnsProduct />} />
                <Route path="chairs" element={<ChairsProduct />} />


                <Route path="atk22" element={<ATK22 />} />
                <Route path="at104" element={<AT104 />} />
                <Route path="atx5" element={<ATX5 />} />

            </Routes>
        </BrowserRouter>

    )
}
export default Routings;
