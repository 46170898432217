import React from 'react';
import CompanyLogo from '../../assets/logo/atcybersport.png'; // Replace with the actual path to your logo image.
import BackgroundImage from '../../assets/Banners/IMG_9482.JPG'; // Replace with the actual path to your background image.
import { PhoneIcon, MailIcon, LocationMarkerIcon } from '@heroicons/react/24/outline';

const AboutUs = () => {
  return (
    <div
      className="min-h-screen py-32 bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})`, backdropFilter: 'blur(10px)' }}
    >
      <div className="max-w-3xl mx-auto px-2 md:px-12 lg:px-12 bg-neutral-950 bg-opacity-50 backdrop-blur-sm h-full h-full rounded-lg">
        <div className="flex items-center justify-center">
          <img src={CompanyLogo} alt="Company Logo" className="w-16 h-16 mr-4" />
          <h1 className="text-3xl font-extrabold text-gray-100">О компании AT-Gaming</h1>
        </div>
        <p className="mt-4 text-lg text-gray-200">
        Добро пожаловать в мир AT-Gaming – ведущего производителя киберспортивных девайсов в Туркменистане высочайшего качества.
         Мы с гордостью представляем широкий ассортимент устройств,
         созданных для того, чтобы увлечь вас в мир электронных сражений и обеспечить непревзойденное игровое взаимодействие.
        </p>

        <p className="mt-4 text-lg text-gray-200">
          На протяжении многих лет мы разрабатываем и совершенствуем свои продукты, чтобы удовлетворить самые высокие
         требования киберспортивных профессионалов и геймеров. Наша команда творческих инженеров, дизайнеров и технических
         специалистов работает над созданием девайсов, которые не только отличаются выдающейся функциональностью,
          но и являются настоящими произведениями искусства.
        </p>

        <p className="mt-4 text-lg text-gray-200">
         Мы уделяем особое внимание каждой детали – от эргономики и эффективности до эстетики и уникального дизайна.
         Наши мыши, клавиатуры, наушники и другие устройства разработаны с учетом требований киберспортивных соревнований,
         чтобы вы могли достичь максимальных результатов и погрузиться в мир игр с полным погружением.
        </p>

        <p className="mt-4 text-lg text-gray-200">
        Мы гордимся тем, что наши продукты помогают геймерам раскрыть свой потенциал,
         достигать новых вершин и воплощать свои амбиции. Наша миссия – предоставить вам инструменты, которые позволят добиваться выдающихся достижений в мире киберспорта.
        </p>

        <h1 className="text-2xl font-bold text-gray-100 py-12">Присоединяйтесь к нам и перенесите свою игровую энергию на новый уровень с AT-Gaming!







</h1>
        {/* The rest of your content */}
      </div>
    </div>
  );
};

export default AboutUs;