// src/VideoBackground.js

import React from 'react';
import { Link } from 'react-router-dom';
import Video1 from "../../assets/Video/Videoback/111.mp4";
import Image2 from "../../assets/logo/atcybersport.png"

const VideoBackground = () => {
    return (
        <div className="relative w-full h-screen overflow-hidden">
            <video
                className="absolute top-0 left-0 object-cover w-full h-full"
                autoPlay
                muted
                loop
                playsInline
                
            >
                <source src={Video1} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            {/* Content overlay */}
            <div className="absolute top-0 left-0 w-full h-full bg-opacity-40 bg-black flex items-center justify-center">
                <div className="text-white text-center">
                    <img className='h-32 w-32 m-auto align-middle justify-center' src={Image2} alt="" />
                    <h1 className="text-4xl font-semibold mb-4">Добро пожаловать в AT-Gaming</h1>
                    <p className="text-lg mb-8">Откройте для себя удивительный мир игровых девайсов.</p>
                    <Link to="/Productpage">
                    <button className="px-16 py-2 bg-purple-900 shadow-neutral-800 hover:shadow-purple-500 text-white font-bold rounded-full transition duration-500 ease-in-out shadow-lg hover:scale-110 hover:bg-white hover:text-black">
                        Перейти в Каталог
                    </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default VideoBackground;