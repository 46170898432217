import React from "react";
                                //Image's
import Image1 from "../assets/at-cybersport-asses/Keyboard/Mehanika/MK853-11.png"
import Image2 from "../assets/at-cybersport-asses/Keyboard/Membrana/AT 104membrana.png"
import Image3 from "../assets/at-cybersport-asses/Case/AT-PC1.png"
import Image4 from "../assets/at-cybersport-asses/Bluetoorh/AT-TX1-2-1200x1200.png"
import Image5 from "../assets/at-cybersport-asses/Headphones/AG15RGB Virtual 7.1 Headphones.png"
import Image6 from "../assets/at-cybersport-asses/Kolonki/AT201-RGB-1200x720.png"
import Image7 from "../assets/at-cybersport-asses/Kovriki/290x250-600x600.png"
import Image8 from "../assets/at-cybersport-asses/Kovriki/400x450-600x600.png"
import Image9 from "../assets/at-cybersport-asses/Kovriki/900x300-600x600.png"
import Image10 from "../assets/at-cybersport-asses/Kreslo/kreslo.png"
import Image11 from "../assets/at-cybersport-asses/Headphones/AG81-HeadPhones.png"
import Image12 from "../assets/at-cybersport-asses/Webcam/AT30.png"
import Image13 from "../assets/at-cybersport-asses/Mouse/A5/a5.png"
import Image14 from "../assets/at-cybersport-asses/Mouse/A7/A7.png"
import Image15 from "../assets/at-cybersport-asses/Mouse/A9/A9-600x600.png"
import Image16 from "../assets/at-cybersport-asses/Case/watch.png"


export const products = [
    {
        id: 0,
        type: 'keyboard',
        title: 'ATK 22',
        description: 'Механическая клавиатура',
        descriptionText: [
            {text:'Механическая игровая клавиатура MAXPOWER MK853'},
            {text:'Количество клавиш: 104 клавиши'},
            {text:'Тип переключателя: механический'},
            {text:'Anti-ghosting: все клавиши '},
            {text:'Размер: 436 (Д) * 220 (Ш) * 42 (В)'},
            {text:'Вес: 1300 гр '},
        ],
        image: Image1,
        url: "/atk22",
    },
    {
        id: 1,
        type: 'keyboard',
        title: 'AT 104',
        description: 'Мембранная клавиатура',
        descriptionText: [
            {text:'Мембранная Игровая Клавиатура AT104'},
            {text:'Всего 104 клавиш'},
            {text:'24 клавишь с защитой от ложных нажатий'},
            {text:'Тип клавиш мембранная'},
            {text:'3х цветная LED подсветка '},
            {text:'10 миллионов нажатий'},
            {text:'Кабель с нейлоновой оплеткой 1,5 мм'},
            {text:'Размеры: 352 мм x 130 мм x 40 мм'},
        ],
        
        image: Image2,
        url: "/at104",
    },
    {
        id: 2,
        type: 'case',
        title: 'AG 72',
        description: 'Игровой корпус для ПК',
        descriptionText: [
            {text:'Тип - Mid Tower'},
            {text:'Габариты - 380*190*423мм'},
            {text:'Вес - 3,95 кг'},
            {text:'Цвет - черный'},
            {text:'Материал верхней крышки - сталь + магнитный фильтр'},
            {text:'Материал передней панели - пластик'},
            {text:'Левая панель - закаленное стекло'},
            {text:'Порты ввода/вывода — 1x 3,5-мм аудиоразъем, 1x 3,5-мм разъем для микрофона , 1x RGB-контроллер, 1x USB 2.0, 1x USB 3.0'},
            {text:'Пылевой фильтр - Имеется (внизу)'},
            {text:'Высота под процессорный кулера — 165 мм'},
            {text:'Длина для блока питания - 140 мм'},
            {text:'Длина для графического процессора — 280 мм'},
            {text:'Поддержка материнских плат — ATX, Micro ATX, Mini ATX'},
            {text:'Слоты PCI - 7'},
            {text:'Sync, RGB Fushion 2.0'},
        ],
        
        image: Image3,
        url: "/singleproduct",
    },
    {
        id: 3,
        type: 'bluetooth',
        title: 'ATX 5',
        description: 'Беcпроводные наушники (Bluetooth)',
        descriptionText: [
            {text:'Версия Bluetooth: V5.0+EDR'},
            {text:'Рабочее расстояние: 10 метров'},
            {text:'Батарея наушников: 40 мА'},
            {text:'Аккумулятор зарядного кейса: 500 мА'},
            {text:'Время работы: до 5 часов'},
            {text:'Время зарядки аккумулятора: 1 час'},
            {text:'Чистый звук и глубокий бас'},
            {text:'USB-C для зарядки'},
            {text:'Сенсорное управление'},
            {text:'Эргономичный дизайн'},
            {text:'Вес: (один наушник): 4,5 гр.'},
        ],
        
        image: Image4,
        url: "/atx5",
    },
    {
        id: 4,
        type: 'headphone',
        title: 'AG 15 RGB Virtual 7.1',
        description: 'Игровые наушники',
        descriptionText: [
            {text:'Виртуальные 7.1 RGB наушники AG15'},
            {text:'Виртуальный объемный звук 7.1'},
            {text:'Диапазон частот наушников 20 — 20000 Гц'},
            {text:'Сопротивление наушников 32 Ом ± 15%'},
            {text:'Чувствительность наушников 108 дБ ± 3 дБ'},
            {text:'Диапазон частот микрофона 50 — 16000 Гц'},
            {text:'Чувствительность микрофона  54 дБ ± 3 дБ'},
            {text:'Диаметр динамиков 50 мм'},
            {text:'Размер микрофона Ø6.0 х 5 мм'},
            {text:'Встроенный регулятор громкости'},
            {text:'Две зоны подсветки (микрофон и наушники)'},
            {text:'Тип подключение USB'},
        ],
        
        image: Image5,
    },
    {
        id: 5,
        type: 'columns',
        title: 'AT 201-RGB',
        description: 'Игровые колонки для ПК',
        descriptionText: [
            {text:'Игровые колонки AT201 RGB'},
            {text:'Тип подключения 1 x mini-jack '},
            {text:'(Разъем 3.5мм), 1 x USB'},
            {text:'Выходная мощность 3 Вт х 2'},
            {text:'Диапазон частот 40 — 20000 Гц'},
            {text:'Диаметр динамиков 45 мм'},
            {text:'Регулятор громкости на кабеле'},
            {text:'Чистое и объемное звучание'},
            {text:'Кабель Аудио — 1.2 м'},
            {text:'Кабель Питания — 1.2 м'},
            {text:'Размер 85 х 85 х 95 мм'},
        ],
        
        image: Image6,
    },
    {
        id: 6,
        type: 'rug',
        title: 'AT-290',
        description: 'Коврик для мыши',
        descriptionText: [
            {text:'Плоская поверхность'},
            {text:'Водонепроницаемая плоская поверхность обеспечивает легкое выравнивание цели и предотвращает появление пятен от жидкости.'},
            {text:'Ощущение скользкости'},
            {text:'Оптимальная толщина 3 мм для поддержания комфорта.'},
            {text:'Угловая волнистая структура изготовлена ​​из противоскользящей резины, нетоксична и экологически безопасна.'},
            {text:'Размер: 290mm х 250mm х 3 мм'},
        ],
        
        image: Image7,
    },
    {
        id: 7,
        type: 'rug',
        title: 'AT-450',
        description: 'Коврик для мыши',
        descriptionText: [
            {text:'Плоская поверхность'},
            {text:'Водонепроницаемая плоская поверхность обеспечивает легкое выравнивание цели и предотвращает появление пятен от жидкости.'},
            {text:'Ощущение скользкости'},
            {text:'Оптимальная толщина 3 мм для поддержания комфорта.'},
            {text:'Угловая волнистая структура изготовлена ​​из противоскользящей резины, нетоксична и экологически безопасна.'},
            {text:'Размер: 450mm x 400mm x 3mm'},
        ],
        price: 14.99,
        image: Image8,
    },
    {
        id: 8,
        title: 'AT-900',
        type: 'rug',
        description: 'Коврик для мыши',
        descriptionText: [
            {text:'Плоская поверхность'},
            {text:'Водонепроницаемая плоская поверхность обеспечивает легкое выравнивание цели и предотвращает появление пятен от жидкости.'},
            {text:'Ощущение скользкости'},
            {text:'Оптимальная толщина 3 мм для поддержания комфорта.'},
            {text:'Угловая волнистая структура изготовлена ​​из противоскользящей резины, нетоксична и экологически безопасна.'},
            {text:'Размер: 900mm x 300mm x 3mm'},
        ],
        
        image: Image9,
    },
    {
        id: 9,
        type: 'chair',
        title: 'AT 192',
        description: 'Кресло для геймера',
        descriptionText: [
            
        ],
        
        image: Image10,
    },
    {
        id: 10,
        type: 'headphone',
        title: 'AG-81',
        description: 'Игровые наушники',
        descriptionText: [
            {text:'Игровые Наушники AG 81'},
            {text:'Диапазон частот наушников 20 — 20000 Гц'},
            {text:'Сопротивление наушников 32 Ом ± 15%'},
            {text:'Чувствительность наушников 110 дБ ± 3 дБ'},
            {text:'Диаметр динамиков 50 мм'},
            {text:'Диапазон частот микрофона 50 — 16000 Гц'},
            {text:'Чувствительность микрофона  42 дБ ± 3 дБ'},
            {text:'Размер микрофона Ø6.0 х 5 мм'},
            {text:'Встроенный регулятор громкости'},
            {text:'Тип подключения 3,5 jack + USB'},
        ],
        
        image: Image11,
    },
    {
        id: 11,
        type: 'webcam',
        title: 'AT-30 (2K)',
        description: 'Вебкамера для стриминга',
        descriptionText: [
            {text:'QHD Веб-камера AT30'},
            {text:'2K Quad HD'},
            {text:'4-мегапиксельная камера'},
            {text:'Угол обзора 106 градусов сверхширокий'},
            {text:'Встроенный микрофон'},
            {text:'Вращение на 360 градусов'},
        ],
        
        image: Image12,
    },
    {
        id: 12,
        type: 'mouse',
        title: 'A5',
        description: 'Игровая мышь',
        descriptionText: [
            {text:'Игровая мышь А5'},
            {text:'Игровой оптический датчик Instan A804'},
            {text:'Регулируемый в полете DPI 200-4,800 DPI'},
            {text:'Скорость до 60 IPS / 15G'},
            {text:'Частота дискретизации: 1000 Гц'},
            {text:'6 программируемых кнопок'},
            {text:'Встроенная память'},
            {text:'10 миллионов нажатий'},
            {text:'Кабель с нейлоновой оплеткой '},
            {text:'Металлический алюминиевый ролик'},
            {text:'Яркая RGB подсветка'},
        ],
        
        image: Image13,
    },
    {
        id: 13,
        type: 'mouse',
        title: 'A7',
        description: 'Игровая мышь',
        descriptionText: [
            {text:'Игровая мышь А7'},
            {text:'Игровой сенсор PixArt 3360'},
            {text:'Ускорение до 250 IPS / 50G'},
            {text:'Оперативная регулировка DPI 200–12 000 DPI'},
            {text:'20 миллионов кликов в течение всего срока службы'},
            {text:'RGB 16,8 миллионов настраиваемых цветовых вариантов'},
            {text:'7 независимо программируемых кнопок'},
            {text:'Кабель из паракорда'},
            {text:'Габариты: 125 мм х 62 мм х 42 мм'},
        ],
        
        image: Image14,
    },
    {
        id: 14,
        type: 'mouse',
        title: 'A9',
        description: 'Игровая мышь',
        descriptionText: [
            {text:'Игровая мышь А9'},
            {text:'Игровой оптический датчик Instan A714'},
            {text:'Скорость до 60 IPS / 15G'},
            {text:'Регулируемый в полете DPI 200-4,800 DPI'},
            {text:'Частота 125 Гц'},
            {text:'10 миллионов кликов'},
            {text:'12 режимов RGB спектра'},
            {text:'7 программируемых кнопок'},
            {text:'Встроенная память '},
            {text:'Кабель с нейлоновой оплеткой диаметром 1,8 мм'},
            {text:'Размеры: 122 мм x 68 мм x 40 мм'},
        ],
        
        image: Image15,
    },
    {
        id: 15,
        type: 'watch',
        title: 'AT-watch "Saglyk"',
        description: 'Смартчасы',
        descriptionText: [
            {text:'Bluetooth: BT 4.0'},
            {text:'Экран: 1,28-дюймовый цветной экран 240*240'},
            {text:'Режим зарядки: Беспроводная зарядка'},
            {text:'Тип батареи: Литий-ионный полимерный'},
            {text:'Емкость аккумулятора: 180 мАч'},
            {text:'Мощность зарядки: DС 5V постоянного тока'},
            {text:'В приложение «AT Watch»  доступны:'},
            {text:'15 настраиваемых циферблатов'},
            {text:'Более 30 спортивных режимов'},
            {text:'Измерение уровня кислорода в крови'},
            {text:'Непрерывный контроль пульса'},
            {text:'Влагозащита 50 метров IP 68'},
            {text:'10 дней автономной работы '},
            {text:'Измерение температуры тела'},
            {text:'Измерение артериального давления'},
            {text:'Функция удаленной камеры'},
            {text:'Функция мониторинга сна'},
            {text:'Управление музыкой с помощью смарт часов'},
        ],
        
        image: Image16,
    },

    // ... add more products here
];
