import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import videoUrl from "../../assets/Video/AT-Gaming-Video/main-promo.mp4"; // Replace with your video URL
import videoBack from "../../assets/Video/black.mp4"; // Replace with your video URL
import 'react-modal-video/css/modal-video.min.css';

const VideoModal = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-950 relative overflow-hidden">
      <video autoPlay loop muted playsInline className="absolute top-0 left-0 min-w-full min-h-full object-cover z-0">
        <source src={videoBack} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="z-1 relative justify-center">
        <h1 className="text-3xl font-bold text-white mb-4 px-4">Узнайте больше о наших продуктах</h1>
        <p className="text-gray-200 text-center mb-8">
          Посмотрите наш рекламный ролик и узнайте больше о том, что мы предлагаем.
        </p>
        <button
          className="px-20 block mx-auto lg:mx-0 py-3 bg-purple-900 hover:shadow-purple-500 text-white font-bold rounded-full transition duration-300 ease-in-out shadow-lg hover:scale-110 hover:bg-white hover:text-black"
          onClick={() => setOpen(true)}
        >
          Смотреть видео
        </button>
      </div>
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50">
          <ModalVideo
            channel="custom"
            isOpen={isOpen}
            url={videoUrl}
            onClose={() => setOpen(false)}
            allowFullScreen={true}
            className="z-10 w-full h-screen"
          />
        </div>
      )}
    </div>
  );
};

export default VideoModal;