import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FilmIcon,
  FingerPrintIcon,
  LinkIcon,
  SquaresPlusIcon,
  XMarkIcon,
  Square2StackIcon,
  CubeTransparentIcon,
  CameraIcon,
  SpeakerWaveIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import Image1 from "../../assets/logo/ATС.png"
import { IconBoxModel2, IconDeviceAirpods, IconDeviceWatch, IconHeadphones, IconKeyboardShow, IconMouse } from '@tabler/icons-react'




const products = [
  { name: 'Наушники', description: ' ', href: '/headphones', icon: IconHeadphones },
  { name: 'Клавиатуры', description: ' ', href: '/keyboards', icon: IconKeyboardShow },
  { name: 'Беспроводные наушники', description: ' ', href: '/bluetoothheadphones', icon: IconDeviceAirpods },
  { name: 'Мыши', description: ' ', href: '/mouses', icon: IconMouse },
  //{ name: 'Часы', description: ' ', href: '/watches', icon: IconDeviceWatch },
  { name: 'Коврики', description: ' ', href: '/rugs', icon: IconBoxModel2 },
  // { name: 'Корпусы', description: ' ', href: '/cases', icon: CubeTransparentIcon },
  // { name: 'Кресла', description: ' ', href: '/chairs', icon: Square2StackIcon },
  // { name: 'Калонки', description: ' ', href: '/columns', icon: SpeakerWaveIcon },
  // { name: 'Камеры', description: ' ', href: '/cameras', icon: CameraIcon },
]
const callsToAction = [
  { name: 'Посмотреть ролик', href: "/videomodal", icon: FilmIcon },
  
]






function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className= "shadow-md fixed z-10 w-full bg-black bg-opacity-60 backdrop-blur-sm ">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">AT Gaming</span>
            <img className="h-auto w-auto px-8" src={Image1} alt="logo" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Открыть меню</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
              Продукты
              <ChevronDownIcon className="h-5 w-5 flex-none text-white" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md bg-opacity-90 backdrop-blur-sm transition duration-500 ease-in-out shadow-md overflow-hidden  rounded-3xl bg-black shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm hover:bg-gray-800"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-800 group-hover:bg-white transition duration-300 ease-in-out">
                        <item.icon className="h-6 w-6 text-gray-100 group-hover:text-indigo-500" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-white">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-100">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-purple-800 hover:text-gray-200 transition duration-500 ease-in-out"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <a href="/productpage" className="text-sm font-semibold leading-6 text-white">
            Каталог
          </a>
          <a href="/gallery" className="text-sm font-semibold leading-6 text-white">
            Галлерея
          </a>
          <a href="/aboutus" className="text-sm font-semibold leading-6 text-white">
            О компании
          </a>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end animate-pulse hover:underline transition duration-500 ease-in-out">
          <a href="/software" className="text-sm font-bold leading-6 text-purple-400 hover:text-white hover:transition duration-500 ease-in-out">
            Программное обеспечение <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10 transition duration-500 ease-in-out" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-gray-950 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 bg-opacity-50 backdrop-blur-sm ">
          <div className="flex items-center justify-between ">
            <a href="/Home" className="-m-1.5 p-1.5">
              <span className="sr-only">AT-Gaming</span>
              <img
                className="h-8 w-auto"
                src={Image1}
                alt="logo"
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-100"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-100 hover:text-gray-900 hover:bg-gray-100">
                        Продукты
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-100 hover:text-gray-900 hover:bg-gray-100"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="/productpage"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 hover:text-gray-900 hover:bg-gray-100"
                >
                  Каталог
                </a>
                <a
                  href="/gallery"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 hover:text-gray-900 hover:bg-gray-100"
                >
                  Галлерея
                </a>
                <a
                  href="/aboutus"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-100 hover:text-gray-900 hover:bg-gray-100"
                >
                  О Компании
                </a>
              </div>
              <div className="py-6">
                <a
                  href="/software"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-sm font-semibold leading-6 text-purple-400"
                >
                  Програмное обеспечение
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}