import React from 'react';
import Image1 from "../../assets/gallery/10.jpg"
import Image2 from "../../assets/gallery/1.jpg"
import Image3 from "../../assets/gallery/2.jpg"
import Image4 from "../../assets/gallery/4.jpg"
import Image5 from "../../assets/gallery/5.jpg"
import Image6 from "../../assets/gallery/6.jpg"
import Image7 from "../../assets/gallery/7.jpg"
import Image8 from "../../assets/gallery/8.jpg"
import Image9 from "../../assets/gallery/9.jpg"

import Video1 from "../../assets/Video/-877.mp4"


const GalleryComponent = () => {
  // Sample video URL
  const videoUrl = Video1;

  // Sample image URLs
  const imageUrls = [
    Image1,
    Image2,
    Image3,
    Image4,
    Image5,
    Image6,
    Image7,
    Image8,
    Image9,
    // Add more image URLs as needed
  ];


  return (
    <div className="relative">
      {/* Video Background */}
      <div className="absolute inset-0 overflow-hidden z-0">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="w-full h-full object-cover"
        >
          <source src={Video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      
      {/* Gallery */}
      <div className="relative z-2 container mx-auto py-24 text-white">
        <h1 className="text-2xl font-bold mb-12 px-4 text-center">Галлерея наших девайсов</h1>
        <h1 className="text-lg mb-2 px-4 font-semibold text-center">Добро пожаловать в мир нашей киберспортивной продукции!</h1>
        <h1 className="text-ьв mb-12 px-12 text-center">Мы гордимся представить вам наши инновационные и высококачественные продукты, разработанные специально для геймеров и киберспортсменов. Наша цель - сделать ваш опыт в мире киберспорта невероятно увлекательным и комфортным.</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {imageUrls.map((imageUrl, index) => (
            <div key={index} className="p-4">
              <img src={imageUrl} alt={`Image ${index + 1}`} className="w-full h-auto rounded-2xl hover:shadow-2xl transition duration-300 ease-in-out shadow-md hover:scale-105 bg-opacity-50 backdrop-blur-sm" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryComponent;