// ProductDisplay.js
import React from 'react';
import { Link } from 'react-router-dom';
import {products} from '../../routings/ProductMap';


const ProductPage = () => {
  console.log(products)
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-950 px-2 ">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-12 mt-40 mb-12">
        {products.map((product, index) => (
          <div
            key={index}
            className="bg-neutral-900 rounded-2xl overflow-hidden p-6 flex flex-col justify-between hover:shadow-purple-900 hover:shadow-2xl transition duration-300 ease-in-out shadow-md hover:scale-105 bg-opacity-50 backdrop-blur-sm"
          >
            <div className="mb-12">
              <img src={product.image} alt={product.title} className="w-full h-32 md:h-48 object-cover" />
              <h2 className="text-lg font-medium text-gray-50 mt-12">{product.title}</h2>
              <p className="mt-2 text-sm text-gray-50">{product.description}</p>
            </div>
            <div className="flex justify-between items-center">
              <p className="PRICE Placeholder text-base font-semibold text-gray-700"></p>
              <Link to={`/singleproduct/${product.id}`}>
                <button onClick={()=>window.scrollTo(0,0)} className="px-8 py-2 m-auto bg-purple-800 font-bold hover:bg-white text-white hover:text-black hover:shadow-md transition duration-500 ease-in-out shadow-md hover:scale-110 justify-center rounded-full">
                  Подробнее
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;

// ${product.price.toFixed(2)}