import React from 'react';
import CompanyLogo from '../../assets/logo/atcybersport.png'; // Replace with your actual logo image.
import BackgroundImage from '../../assets/Banners/IMG_9482.JPG'; // Replace with your actual background image.
import PhoneIcon from '@heroicons/react/20/solid/PhoneIcon';
import MailIcon from '@heroicons/react/20/solid/MinusSmallIcon';
import LocationMarkerIcon from '@heroicons/react/20/solid/ArrowLeftOnRectangleIcon';
import VideoBack from "../../assets/Video/VideoBack.mp4"
import { EnvelopeIcon } from '@heroicons/react/20/solid';
import { MapIcon } from '@heroicons/react/24/outline';

const ContactUs = () => {
    return (
        <div className="min-h-screen relative">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="w-full h-full absolute object-cover top-0 left-0 z-0"
          >
            <source src={VideoBack} type="video/mp4" />
            Ваш браузер не поддерживает видео тег.
          </video>
    
          <div className="relative z-1 min-h-screen flex flex-col justify-center items-center bg-opacity-80 bg-black text-white">
            <h1 className="text-4xl md:text-6xl font-bold mb-2 justify-center items-center text-center">Свяжитесь с нами</h1>
            <h4 className="text-2xl md:text-2xl font-bold mb-12 text-center">Мы ценим ваше мнение!</h4>
            <p className="text-md md:text-xl mb-0 px-2 text-center">Ваше мнение имеет большое значение для нас, и мы всегда рады услышать ваши предложения,</p>
            <p className="text-md md:text-xl mb-8 px-2 text-center">комментарии и замечания относительно наших продуктов и услуг. Ниже вы найдете различные способы, как связаться с нами:</p>

            
            <div className="flex items-center mb-4">
              <PhoneIcon className="w-6 h-6 mr-2" />
              <span>+99365 77 77 77</span>
            </div>
    
            <div className="flex items-center mb-4">
              <EnvelopeIcon className="w-6 h-6 mr-2" />
              <span>contact-atgaming@gmail.com</span>
            </div>
    
            <div className="flex items-center">
              <MapIcon className="w-6 h-6 mr-2" />
              <span>ТЦ Чоганлы, магазин 111А</span>
            </div>
          </div>
        </div>
      );
    };

export default ContactUs;