import React from "react";
import ScrollToTop from "./components/ScroolToTop/ScroolToTop";
import Navbar from "./components/Navbar/Navbar"
import Footer from "./components/Footer/Footer";
import Routings from "./routings/Routings"
import FooterNavigation from "./pages/FooterNavigation/FooterNavigation";
import { Breadcrumbs } from "@material-tailwind/react";

function App() {
  return (
    <div className="App bg-neutral-950">

    <Navbar/>
    <Breadcrumbs/>
      {/* <PreLoader/> */}
        <ScrollToTop />


            {/* Роутинги по страничкам */}
                     <Routings />
          {/* <FooterNavigation /> */}
        <Footer />

    </div>
  );
}


export default App;
