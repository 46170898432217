import React from "react";
import {
    Card,
    CardBody,
    CardFooter,
    Typography,
    Button,
  } from "@material-tailwind/react";
  import Image1 from "../../assets/Banners/atcybersport_1688489402708515.jpg"
   
  export default function CardDefault3() {
    return (
      <div class="max-w-sm bg-gray-800 border border-gray-700 rounded-lg shadow transition duration-500 hover:scale-105">
      <a href="#">
          <img class="rounded-t-lg" src={Image1} alt="" />
      </a>
      <div class="p-6">
          <a href="/singleproduct/3">
              <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Беспроводные наушники</h5>
          </a>
          <p class="mb-20 font-normal text-gray-700 dark:text-gray-400">Освободите себя от проводов и наслаждайтесь превосходным звучанием с беспроводными Bluetooth наушниками. Они позволяют вам наслаждаться музыкой и звуком без ограничений, где бы вы ни находились. Идеальный спутник для активного образа жизни и музыкальных наслаждений.</p>
          <a href="/singleproduct/3" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white transition duration-500 ease-in-out bg-purple-700 rounded-full hover:bg-white hover:text-black focus:ring-4">
              Подробнее
               <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
          </a>
      </div>
  </div>
    );
  }