import React from 'react';
//Image URL's
import A5Image from '../../assets/at-cybersport-asses/Mouse/A5/a5.png'; // Замените на путь к изображению для программы A5
import A7Image from "../../assets/at-cybersport-asses/Mouse/A7/A7.png"
import A9Image from "../../assets/at-cybersport-asses/Mouse/A9/A9-600x600.png"
import ATK22Image from "../../assets/at-cybersport-asses/Keyboard/Mehanika/MK853-11.png"
import ATK22RUENImage from "../../assets/at-cybersport-asses/Keyboard/Mehanika/MK853-2.png"
import AG15Image from "../../assets/at-cybersport-asses/Headphones/AG15RGB Virtual 7.1 Headphones.png"

//Soft URL's
import A5url from "../../soft/A5-Oyun-Sycanjygy.20211029.zip"
import A7url from "../../soft/AT-Cybersport-A7-Setup-V1.0.5-202110281.zip"
import A9url from "../../soft/A9-Oyun-Sycanjygy.202111011.zip"
import ATK22url from "../../soft/ATK22-Mehaniki-Klawiaturasy-setup-v1.0.0.3Turkmen.zip"
import ATK22RUENurl from "../../soft/ATK22-Mehaniki-Klawiaturasy-setup-v1.0.0.3Russian-English.zip"
import AG15url from "../../soft/AG15.zip"

const SoftwarePage = () => {
  const softwareList = [
    {
      title: 'A5',
      description: 'Программное обеспечение для мыши A5',
      downloadLink: A5url,
      image: A5Image,
    },

    {
      title: 'A7',
      description: 'Программное обеспечение для мыши A7',
      downloadLink: A7url,
      image: A7Image,
    },
{
    title: 'A9',
    description: 'Программное обеспечение для мыши A9',
    downloadLink: A9url,
    image: A9Image,
  },
  {
    title: 'ATK 22',
    description: 'Программное обеспечение для механической клавиатуры ATK 22 (Turkmen)',
    downloadLink: ATK22url,
    image: ATK22Image,
  },
  {
    title: 'ATK 22',
    description: 'Программное обеспечение для механической клавиатуры ATK 22 ( RU/EN )',
    downloadLink: ATK22RUENurl,
    image: ATK22RUENImage,
  },
  {
    title: 'AG 15',
    description: 'Программное обеспечение для игровых наушников AG 15',
    downloadLink: AG15url,
    image: AG15Image,
  },

    // ... Другие программы
  ];

  return (
    <div className="max-w-5xl mx-auto p-12 sm:px-6 lg:px-8 w-screen ">
      <h1 className="text-3xl font-bold text-white pt-24 pb-12 z-10 ">Программное обеспечение</h1>
      <h1 className="text-ьв mb-12 text-white px-12 text-center">Чтобы скачать наше программное обеспечение, выберите требуемый девайс, далее нажмите на кнопку "Скачать". После завершения загрузки, запустите установку программы.</h1>

      <div className="mt-6 grid gap-6 grid-cols-1 md:grid-cols-1 lg:grid-cols-3 ">
        {softwareList.map((software, index) => (
          <div
            key={index}
            className="flex flex-col justify-between border border-purple-900 bg-opacity-50 backdrop-blur-sm rounded-2xl p-6 bg-gray-900 hover:shadow-purple-900 hover:shadow-2xl transition duration-300 ease-in-out hover:scale-105"
          >
            <h2 className="text-lg font-semibold text-gray-100">{software.title}</h2>
            <img
              src={software.image}
              alt={`${software.title} Image`}
              className="mt-4 rounded-md"
            />
            <p className="mt-6 text-gray-100">{software.description}</p>
            <div className="mt-8">
              <a
                href={software.downloadLink}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-24 justify-center align-bottom py-2 text-black font-bold bg-white rounded-full hover:text-white hover:bg-purple-700 transition duration-300 ease-in-out"
              >
                Скачать
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoftwarePage;