import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import Image1 from "../../assets/Banners/atcybersport_1688489421854670.jpg"
 
export function CardDefault() {
  return (
    <div class="max-w-sm bg-gray-800 border border-gray-700 rounded-lg shadow transition duration-500 hover:scale-105">
    <a href="#">
        <img class="rounded-t-lg" src={Image1} alt="" />
    </a>
    <div class="p-6">
        <a href="">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-950 dark:text-white">Игровые наушники</h5>
        </a>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">Наушники 7.1 - это ключ к углублению в мир аудио развлечений. С этими наушниками вы переживете звуковые эффекты и музыку в новом измерении благодаря пространственной звуковой атмосфере 7.1. Наушники 7.1 - это не просто наушники, это погружение в аудиовизуальный мир на новом уровне. Откройте для себя звук высокой четкости и пространственную звуковую атмосферу с этими наушниками и углубитесь в каждое звуковое приключение.</p>
        <a href="/headphones" class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white transition duration-500 ease-in-out bg-purple-700 rounded-full hover:bg-white hover:text-black focus:ring-4">
            Подробнее
             <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
            </svg>
        </a>
    </div>
</div>
  );
}