import React from "react";

const stats = [
    { label: 'Наши клиенты', value: '1000+' },
    { label: 'Проданно продукции', value: '5000+' },
    { label: 'Новых клиентов', value: '300+' },
  ]
  
  export default function Example() {
    return (
      <div className="bg-neutral-950 py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">Наша миссия</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-100">
                Миссия AT Gaming: Стремление к совершенству в мире киберспортивных устройств.
                Мы верим, что качественные киберспортивные устройства могут изменить игровой опыт и помочь игрокам достичь новых высот в мире киберспорта.
                Наша миссия - предоставлять геймерам по всему миру инструменты, которые делают их игры более захватывающими, эффективными и приносят радость.
                </p>
                <p className="mt-10 max-w-xl text-base leading-7 text-gray-100">
                Мы гордимся тем, что помогаем игрокам по всему миру улучшить свой игровой опыт.
                 Мы стремимся к совершенству и готовы продолжать разрабатывать новые продукты, которые делают мир киберспорта еще захватывающим.
                </p>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-100">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-100">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }