import React from "react";
import { Link } from "react-router-dom";
import BannerCarousel from "../../components/BannerCarousel/BannerCarousel";
import Card, { CardDefault } from "../../components/CardDefault/CardDefault";
import CardDefault2 from "../../components/CardDefault/CardDefault2"
import CardDefault3 from "../../components/CardDefault/CardDefault3"
import VideoBackground from "../../components/VideoBackground/VideoBackground";
import Banner from "../../components/Banner/Banner";
import FooterNavigation from "../FooterNavigation/FooterNavigation";
import Stats from "../../components/Stats/Stats"


function Home(){
    return(
        <>

<VideoBackground/>  
            <div className="flex flex-wrap justify-center lg:space-x-12 space-y-2 lg:px-24 mt-10 mb-10">     
                <CardDefault/>
                <CardDefault2/>
                <CardDefault3/>
            </div> 
        <Banner/>
    <Stats/>
<FooterNavigation/>

        </>
    )
};
export default Home;