import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Image1 from "../../assets/Banners/IMG_9497.JPG";
import Image2 from "../../assets/Banners/IMG_9496.JPG";
import Image3 from "../../assets/Banners/IMG_9494.JPG";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    fade: true,

  };

  const slideData = [
    Image1,
    Image2,
    Image3,
    // Add more image filenames as needed
  ];

  return (
    <div className="relative max-w-screen-2xl max-h-screen-5xl mx-auto px-10 sm:px-6 m-auto">
      <Slider {...settings}>
        {slideData.map((slide, index) => (
          <div key={index}>
            <img
              src={slide}
              alt={`Slide ${index + 1}`}
              className="w-full rounded-3xl shadow-md h-1/4"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Banner;